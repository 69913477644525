import React from 'react'
import { Wrapper } from './Styled-components'

const Content = () => {
  return (
    <>
      <Wrapper>
        <h1>Cookie Policy</h1>
        <p>
          This privacy policy applies between you, the User of this Website and
          Suada Corporation Limited, the owner and provider of this Website.
          Suada Corporation Limited takes the privacy of your information very
          seriously. This privacy policy applies to our use of any and all Data
          collected by us or provided by you in relation to your use of the
          Website.
        </p>
        <h6>Please read this privacy policy carefully.</h6>
        <h6 style={{ marginBottom: 20 }}>Definitions and interpretation</h6>
        <ol>
          <li>
            1. In this privacy policy, the following definitions are used:
          </li>
          <table className="cookie-table">
            <tr>
              <td className="bolder">DATA</td>
              <td>
                collectively all information that you submit to Suada
                Corporation Limited via the Website. This definition
                incorporates, where applicable, the definitions provided in the
                Data Protection Laws
              </td>
            </tr>
            <tr>
              <td className="bolder">Cookies</td>
              <td>
                a small text file placed on your computer by this Website when
                you visit certain parts of the Website and/or when you use
                certain features of the Website. Details of the cookies used by
                this Website are set out in the clause below ( Cookies);
              </td>
            </tr>
            <tr>
              <td className="bolder">Data Protection Laws</td>
              <td>
                any applicable law relating to the processing of personal Data,
                including but not limited to the Directive 96/46/EC (Data
                Protection Directive) or the GDPR, and any national implementing
                laws, regulations and secondary legislation, for as long as the
                GDPR is effective in the UK;
              </td>
            </tr>
            <tr>
              <td className="bolder">GDPR</td>
              <td>the General Data Protection Regulation (EU) 2016/679;</td>
            </tr>
            <tr>
              <td className="bolder">Suada Corporation Limited, we or us</td>
              <td>
                Suada Corporation Limited, a company incorporated in England and
                Wales with registered number 12171457 whose registered office is
                at The 7 Ridgway Road, Barton Seagrave, Kettering, Northants.
                NN15 5AQ;{' '}
              </td>
            </tr>
            <tr>
              <td className="bolder">UK and EU Cookie Law</td>
              <td>
                the Privacy and Electronic Communications (EC Directive)
                Regulations 2003 as amended by the Privacy and Electronic
                Communications (EC Directive) (Amendment) Regulations 2011;
              </td>
            </tr>
            <tr>
              <td className="bolder">User or you</td>
              <td>
                any third party that accesses the Website and is not either (i)
                employed by Suada Corporation Limited and acting in the course
                of their employment or (ii) engaged as a consultant or otherwise
                providing services to Suada Corporation Limited and accessing
                the Website in connection with the provision of such services;
              </td>
            </tr>
            <tr>
              <td className="bolder">Website</td>
              <td>
                the website that you are currently using,
                www.influencebusiness.com, and any sub-domains of this site
                unless expressly excluded by their own terms and conditions.
              </td>
            </tr>
          </table>
          <li>
            2. In this privacy policy, unless the context requires a different
            interpretation:
          </li>
          <ul>
            <li>a. the singular includes the plural and vice versa;</li>
            <li>
              b. references to sub-clauses, clauses, schedules or appendices are
              to sub-clauses, clauses, schedules or appendices of this privacy
              policy;
            </li>
            <li>
              c. a reference to a person includes firms, companies, government
              entities, trusts and partnerships;
            </li>
            <li>
              d. "including" is understood to mean "including without
              limitation";
            </li>
            <li>
              e. reference to any statutory provision includes any modification
              or amendment of it;
            </li>
            <li>
              f. the headings and sub-headings do not form part of this privacy
              policy.
            </li>
          </ul>
        </ol>

        <h6>Scope of this privacy policy</h6>
        <p>
          This privacy policy applies only to the actions of Suada Corporation
          Limited and Users with respect to this Website. It does not extend to
          any websites that can be accessed from this Website including, but not
          limited to, any links we may provide to social media websites.
        </p>

        <p>
          For purposes of the applicable Data Protection Laws, Suada Corporation
          Limited is the "data controller". This means that Suada Corporation
          Limited determines the purposes for which, and the manner in which,
          your Data is processed.
        </p>

        <h6>Data collected</h6>
        <p>
          We may collect the following Data, which includes personal Data, from
          you:
        </p>
        <ul>
          <li>a. name</li>
          <li>b. date of birth</li>
          <li>c. gender</li>
          <li>d. job title</li>
          <li>e. profession</li>
          <li>
            f. contact Information such as email addresses and telephone
            numbers;
          </li>
          <li>
            g. demographic information such as postcode, preferences and
            interests
          </li>
          <li>h. financial information such as credit / debit card numbers;</li>
          <li>i. IP address (automatically collected);</li>
          <li>j. web browser type and version (automatically collected);</li>
          <li>k. operating system (automatically collected);</li>
          <li>
            1. a list of URLs starting with a referring site, your activity on
            this Website, and the site you exit to (automatically collected); in
            each case, in accordance with this privacy policy.
          </li>
        </ul>

        <h6>How we collect Data</h6>
        <ul>
          <li>a. data is given to us by you</li>
          <li>b. data is collected automatically.</li>
        </ul>

        <h6>Data that is given to us by you</h6>
        <p>
          Suada Corporation Limited will collect your Data in a number of ways,
          for example:
        </p>
        <ul>
          <li>
            a. when you contact us through the Website, by telephone, post,
            e-mail or through any other means;
          </li>
          <li>
            b. when you register with us and set up an account to receive our
            products/services;
          </li>
          <li>
            c. when you complete surveys that we use for research purposes
            (although you are not obliged to respond to them);
          </li>
          <li>
            d. when you enter a competition or promotion through a social media
            channel;
          </li>
          <li>
            e. when you make payments to us, through this Website or otherwise;
          </li>
          <li>
            f. when you elect to receive marketing communications from us;
          </li>
          <li>g. when you use our services;</li>
          <li>in each case, in accordance with this privacy policy.</li>
        </ul>

        <h6>Data that is collected automatically</h6>
        <ul>
          <p>
            To the extent that you access the Website, we will collect your Data
            automatically, for example:
          </p>
          <li>
            a. we automatically collect some information about your visit to the
            Website. This information helps us to make improvements to Website
            content and navigation, and includes your IP address, the date,
            times and frequency with which you access the Website and the way
            you use and interact with its content.
          </li>
          <li>
            b. we will collect your Data automatically via cookies, in line with
            the cookie settings on your browser. For more information about
            cookies, and how we use them on the Website, see the section below,
            headed "Cookies".
          </li>
        </ul>

        <h6>Our use of Data</h6>
        <ul>
          <p>
            Any or all of the above Data may be required by us from time to time
            in order to provide you with the best possible service and
            experience when using our Website. Specifically, Data may be used by
            us for the following reasons:
          </p>
          <li>a. internal record keeping;</li>
          <li>b. improvement of our products / services;</li>
          <li>
            c. transmission by email of marketing materials that may be of
            interest to you;
          </li>
          <li>
            d. contact for market research purposes which may be done using
            email, telephone, fax or mail. Such information may be used to
            customise or update the Website;
          </li>
          <li>in each case, in accordance with this privacy policy.</li>
        </ul>

        <p>
          We may use your Data for the above purposes if we deem it necessary to
          do so for our legitimate interests. If you are not satisfied with
          this, you have the right to object in certain circumstances (see the
          section headed "Your rights" below).
        </p>
        <p>
          For the delivery of direct marketing to you via e-mail, we'll need
          your consent, whether via an opt-in or soft-opt-in:
        </p>
        <ul>
          <li>
            a. soft opt-in consent is a specific type of consent which applies
            when you have previously engaged with us (for example, you contact
            us to ask us for more details about a particular product/service,
            and we are marketing similar products/services). Under "soft opt-in"
            consent, we will take your consent as given unless you opt-out.
          </li>
          <li>
            b. for other types of e-marketing, we are required to obtain your
            explicit consent; that is, you need to take positive and affirmative
            action when consenting by, for example, checking a tick box that
            we'll provide.
          </li>
          <li>
            if you are not satisfied about our approach to marketing, you have
            the right to withdraw consent at any time. To find out how to
            withdraw your consent, see the section headed "Your rights" below.
          </li>
        </ul>

        <p>
          When you register with us and set up an account to receive our
          services, the legal basis for this processing is the performance of a
          contract between you and us and/or taking steps, at your request, to
          enter into such a contract.
        </p>
        <p>
          We may use your Data to show you Suada Corporation Limited adverts and
          other content on other websites. If you do not want us to use your
          data to show you Suada Corporation Limited adverts and other content
          on other websites, please turn off the relevant cookies (please refer
          to the section headed "Cookies" below).
        </p>

        <h6>Who we share Data with:</h6>
        <p>
          We may share your Data with the following groups of people for the
          following reasons:
        </p>
        <ul>
          <li>
            a. our employees, agents and/or professional advisors - to obtain
            advice from professional advisors and for fulfilment of our
            services;
          </li>
          <li>
            b. third party service providers who provide services to us which
            require the processing of personal data - to help third party
            service providers in receipt of any shared data to perform functions
            on our behalf to help ensure the website runs smoothly;
          </li>
          <li>
            c. third party payment providers who process payments made over the
            Website - to enable third party payment providers to process user
            payments and refunds;
          </li>
          <li>in each case, in accordance with this privacy policy.</li>
        </ul>

        <h6>Keeping Data secure</h6>
        <ul>
          <p>
            We will use technical and organisational measures to safeguard your
            Data, for example:
          </p>
          <li>
            a. access to your account is controlled by a password and a user
            name that is unique to you.
          </li>
          <li>b. we store your Data on secure servers.</li>
          <li>
            payment details are encrypted using SSL technology (typically you
            will see a lock icon or green address bar (or both) in your browser
            when we use this technology.
          </li>
        </ul>

        <p>
          Technical and organisational measures include measures to deal with
          any suspected data breach. If you suspect any misuse or loss or
          unauthorised access to your Data, please let us know immediately by
          contacting us via this e-mail address: dt@influencebusiness.com.
        </p>
        <p>
          If you want detailed information from Get Safe Online on how to
          protect your information and your computers and devices against fraud,
          identity theft, viruses and many other online problems, please visit
          www.getsafeonline.org. Get Safe Online is supported by HM Government
          and leading businesses.
        </p>
        <h6>Data retention</h6>
        <p>
          Unless a longer retention period is required or permitted by law, we
          will only hold your Data on our systems for the period necessary to
          fulfil the purposes outlined in this privacy policy or until you
          request that the Data be deleted.
        </p>
        <p>
          Even if we delete your Data, it may persist on backup or archival
          media for legal, tax or regulatory purposes.
        </p>

        <h6>Your rights</h6>
        <ul>
          <p>You have the following rights in relation to your Data:</p>
          <li>
            a. Right to access - the right to request (i) copies of the
            information we hold about you at any time, or (ii) that we modify,
            update or delete such information. If we provide you with access to
            the information we hold about you, we will not charge you for this,
            unless your request is "manifestly unfounded or excessive." Where we
            are legally permitted to do so, we may refuse your request. If we
            refuse your request, we will tell you the reasons why.
          </li>
          <li>
            b. Right to correct - the right to have your Data rectified if it is
            inaccurate or incomplete.
          </li>
          <li>
            c. Right to erase - the right to request that we delete or remove
            your Data from our systems.
          </li>
          <li>
            d. Right to restrict our use of your Data - the right to "block" us
            from using your Data or limit the way in which we can use it.
          </li>
          <li>
            e. Right to data portability - the right to request that we move,
            copy or transfer your Data.
          </li>
          <li>
            f. Right to object - the right to object to our use of your Data
            including where we use it for our legitimate interests.
          </li>
        </ul>
        <p>
          To make enquiries, exercise any of your rights set out above, or
          withdraw your consent to the processing of your Data (where consent is
          our legal basis for processing your Data), please contact us via this
          e-mail address: dt@influencebusiness.com.
        </p>
        <p>
          If you are not satisfied with the way a complaint you make in relation
          to your Data is handled by us, you may be able to refer your complaint
          to the relevant data protection authority. For the UK, this is the
          Information Commissioner's Office (ICO). The ICO's contact details can
          be found on their website at https://ico.org.uk/.
        </p>
        <p>
          It is important that the Data we hold about you is accurate and
          current. Please keep us informed if your Data changes during the
          period for which we hold it.
        </p>

        <h6>Links to other websites</h6>
        <p>
          This Website may, from time to time, provide links to other websites.
          We have no control over such websites and are not responsible for the
          content of these websites. This privacy policy does not extend to your
          use of such websites. You are advised to read the privacy policy or
          statement of other websites prior to using them.
        </p>

        <h6>Changes of business ownership and control</h6>
        <p>
          Suada Corporation Limited may, from time to time, expand or reduce our
          business and this may involve the sale and/or the transfer of control
          of all or part of Suada Corporation Limited. Data provided by Users
          will, where it is relevant to any part of our business so transferred,
          be transferred along with that part and the new owner or newly
          controlling party will, under the terms of this privacy policy, be
          permitted to use the Data for the purposes for which it was originally
          supplied to us.
        </p>
        <p>
          We may also disclose Data to a prospective purchaser of our business
          or any part of it.
        </p>
        <p>
          In the above instances, we will take steps with the aim of ensuring
          your privacy is protected.
        </p>

        <h6>Cookies</h6>
        <p>
          This Website may place and access certain Cookies on your computer.
          Suada Corporation Limited uses Cookies to improve your experience of
          using the Website and to improve our range of services. Suada
          Corporation Limited has carefully chosen these Cookies and has taken
          steps to ensure that your privacy is protected and respected at all
          times.
        </p>
        <p>
          All Cookies used by this Website are used in accordance with current
          UK and EU Cookie Law.
        </p>
        <p>
          Before the Website places Cookies on your computer, you will be
          presented with a message bar requesting your consent to set those
          Cookies. By giving your consent to the placing of Cookies, you are
          enabling Suada Corporation Limited to provide a better experience and
          service to you. You may, if you wish, deny consent to the placing of
          Cookies; however certain features of the Website may not function
          fully or as intended.
        </p>
        <p>This Website may place the following Cookies:</p>
        <table className="cookie-table">
          <tr>
            <th className="bolder">Type of Cookie</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>Strictly necessary cookies</td>
            <td>
              These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to log
              into secure areas of our website, use a shopping cart or make use
              of e-billing services.
            </td>
          </tr>
          <tr>
            <td>Analytical/performance cookiess</td>
            <td>
              They allow us to recognise and count the number of visitors and to
              see how visitors move around our website when they are using it.
              This helps us to improve the way our website works, for example,
              by ensuring that users are finding what they are looking for
              easily.
            </td>
          </tr>
          <tr>
            <td>Functionality cookies</td>
            <td>
              These are used to recognise you when you return to our website.
              This enables us to personalise our content for you, greet you by
              name and remember your preferences (for example, your choice of
              language or region).
            </td>
          </tr>
          <tr>
            <td>Targeting cookies</td>
            <td>
              These cookies record your visit to our website, the pages you have
              visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on
              it more relevant to your interests. We may also share this
              information with third parties for this purpose.
            </td>
          </tr>
        </table>
        <p>
          You can find a list of Cookies that we use in the Cookies Schedule.
        </p>
        <p>
          You can choose to enable or disable Cookies in your internet browser.
          By default, most internet browsers accept Cookies but this can be
          changed. For further details, please consult the help menu in your
          internet browser.
        </p>
        <p>
          You can choose to delete Cookies at any time; however you may lose any
          information that enables you to access the Website more quickly and
          efficiently including, but not limited to, personalisation settings.
        </p>
        <p>
          It is recommended that you ensure that your internet browser is
          up-to-date and that you consult the help and guidance provided by the
          developer of your internet browser if you are unsure about adjusting
          your privacy settings.
        </p>
        <p>
          For more information generally on cookies, including how to disable
          them, please refer to aboutcookies.org. You will also find details on
          how to delete cookies from your computer.
        </p>

        <h6>General</h6>
        <p>
          You may not transfer any of your rights under this privacy policy to
          any other person. We may transfer our rights under this privacy policy
          where we reasonably believe your rights will not be affected.
        </p>
        <p>
          If any court or competent authority finds that any provision of this
          privacy policy (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of this privacy policy will not be affected.
        </p>
        <p>
          Unless otherwise agreed, no delay, act or omission by a party in
          exercising any right or remedy will be deemed a waiver of that, or any
          other, right or remedy.
        </p>
        <p>
          This Agreement will be governed by and interpreted according to the
          law of England and Wales. All disputes arising under the Agreement
          will be subject to the exclusive jurisdiction of the English and Welsh
          courts.
        </p>
        <h6>Changes to this privacy policy</h6>
        <p>
          Suada Corporation Limited reserves the right to change this privacy
          policy as we may deem necessary from time to time or as may be
          required by law. Any changes will be immediately posted on the Website
          and you are deemed to have accepted the terms of the privacy policy on
          your first use of the Website following the alterations. You may
          contact Suada Corporation Limited by email at david@suada.com
        </p>

        <h6>Cookies</h6>
        <p>
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </p>
        <p>Strictly necessary</p>
        <p>We use the following strictly necessary cookies:</p>
        <table>
          <tr>
            <th className="bolder">Description of Cookie</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td></td>
            <td>
              We use this session cookie to remember you and maintain your
              session whilst you are using our website.
            </td>
          </tr>
        </table>
        <p>Analytical/performance</p>
        <p>We use the following analytical/performance cookies:</p>
        <table>
          <tr>
            <th className="bolder">Description of Cookie</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td></td>
            <td>
              We use this cookie to help us analyse how users use the website
            </td>
          </tr>
        </table>
        <p> Functionality</p>
        <p>We use the following functionality cookies:</p>
        <table>
          <tr>
            <th className="bolder">Description of Cookie</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td></td>
            <td>
              We use this cookie to identify your computer and analyse traffic
              patterns on our website
            </td>
          </tr>
        </table>
        <p>Targeting</p>
        <p>We use the following targeting cookies:</p>
        <table>
          <tr>
            <th className="bolder">Description of Cookie</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td></td>
            <td>
              We use this cookie to enable us to show you adverts while you are
              browsing our website and other websites on the internet
            </td>
          </tr>
        </table>
      </Wrapper>
    </>
  )
}

export default Content
